import styled from '@emotion/styled'
import { Swiper } from 'swiper/react'
import Link from '@/components/LocalizedGatsbyLink'

import 'swiper/css'
import 'swiper/css/virtual'

import { mediaMax, sectionSubtitle } from '@/styles/mixins'

import { GridContainer as GridContainerBase } from '@/components/Grid'
import CircularLabelButtonBase from '@/components/CircularLabelButton'

const ANIMATION_DURATION = 400

export const Container = styled.div`
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.brown};
  ${mediaMax.lg} {
    height: 60rem;
  }
  ${mediaMax.sm} {
    min-height: 70rem;
    height: 100vh;
  }
`
export const ItemLink = styled(Link)`
  text-decoration: none;
`

export const Background = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  &::before{
    content: '';
    display: inline-block;
    height: 100%;
    width: 100%;
    background-color: #00000040;
  }
`

export const BackgroundImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
  opacity: 0;
  transition: all ease-in-out ${ANIMATION_DURATION}ms;

  &.active {
    opacity: 1;
  }
`

export const GridContainer = styled(GridContainerBase)`
  z-index: 2;
`

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 2;

  ${mediaMax.xs} {
    flex-direction: column;
    gap: 3rem;
  }
`

export const Content = styled.div`
  width: 50%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;

  ${mediaMax.xs} {
    width: 100%
  }
`

export const Label = styled.h2`
  ${sectionSubtitle()}
  color: ${({ theme }) => theme.colors.white};
  margin-bottom: 5.2rem;

  ${mediaMax.xs} {
    margin-bottom: 2.5rem;
  }
`

export const ListWrapper = styled.div`
  width: 100%;
  position: relative;
`

export const List = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`

export const Item = styled.h3`
  cursor: pointer;
  font-family: ${({ theme }) => theme.fonts.serif};
  font-weight: bold;
  color: ${({ theme }) => `${theme.colors.white}50`};
  font-size: 6rem;
  margin-bottom: 4rem;
  transition: color ease-out ${ANIMATION_DURATION / 2}ms;

  ${mediaMax.md} {
    font-size: 5rem;
  }

  ${mediaMax.xs} {
    font-size: 3.5rem;
    margin-bottom: 2.5rem;
  }

  &:hover {
    color: ${({ theme }) => `${theme.colors.white}70`};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const AnimatedList = styled(List)`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  clip: ${({ path }) => path};

  transition: clip ease-out ${ANIMATION_DURATION}ms;

  ${Item} {
    color: ${({ theme }) => theme.colors.white};
  }
`

export const Images = styled.div`
  margin-right: 50px;
  width: 35%;
  padding-top: 45%;
  position: relative;

  ${mediaMax.xs} {
    width: 100%;
    padding-top: 100%;
    margin-right: 0px;
  }
`

export const SwiperImageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`

export const SwiperImage = styled(Swiper)`
  width: 100%;
  height: 100%;

  .swiper-slide {
    width: 100%;
  }

  .swiper-wrapper {
    ${mediaMax.xs} {
      display: block;
    }
  }
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const CircularLabelButton = styled(CircularLabelButtonBase)`
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translate(-85%, -85%) scale(1.5);
  z-index: 3;
  opacity: 0;
  pointer-events: none;
  transition: opacity ease ${ANIMATION_DURATION}ms;

  ${mediaMax.xs} {
    top: 96%;
    left: 84%;
    transform: translate(-50%, -50%) scale(1);
  }

  &.active {
    pointer-events: initial;
    opacity: 1;
    transition: opacity ease ${ANIMATION_DURATION}ms ${ANIMATION_DURATION}ms;
  }
`
