import PropTypes from 'prop-types'
import React, { useRef, useState } from 'react'

import resolveAssetURL from '@/utils/resolveAssetURL'

import { isMobileState } from '@/recoil/layout'
import { useRecoilValue } from 'recoil'

import { useMountEffect, useUpdateEffect } from '@/hook'
import getRelativeOffset from '@/utils/relativeOffset'

import { SwiperSlide } from 'swiper/react'

import {
  AnimatedList, Background,
  BackgroundImage, CircularLabelButton, Container, Content, GridContainer, Image, Images, Item,
  ItemLink, Label, List, ListWrapper, SwiperImage, SwiperImageWrapper, Wrapper
} from './style'

const WinerySlider = ({ id, secondId, label = '', items }) => {
  const [index, setIndex] = useState(0)
  const [path, setPath] = useState('')
  const listRef = useRef(null)
  const swiperRef = useRef(null)
  const isMobile = useRecoilValue(isMobileState)

  const kebabCase = string => string
    .replace(/([a-z])([A-Z])/g, '$1-$2')
    .replace(/[\s_]+/g, '-')
    .toLowerCase()

  useMountEffect(() => {
    getPath()
  })

  useUpdateEffect(() => {
    getPath()
    swiperRef?.current?.swiper?.slideTo(index)
  }, [index])

  const handleClick = (i) => {
    setIndex(i)
  }

  const handleKeyPress = (i) => {
    if (event.key == 'Enter') {
      setIndex(i)
    }
  }

  const handleSwipe = (swiper) => {
    handleClick(swiper.realIndex)
  }

  const getPath = () => {
    const list = listRef.current

    if (!list) {
      return ''
    }

    const item = list.children[index]
    const w = list.offsetWidth
    const h = item.offsetHeight
    const offset = getRelativeOffset(item, list)

    setPath(`rect(${offset.top}px, ${w}px, ${offset.top + h}px, 0)`)
  }

  const isSingleItem = items.length < 2

  return (
    <Container>
      <Background>
        {items.map((item, i) => (
          <BackgroundImage
            loading='lazy'
            src={ resolveAssetURL(item.background) }
            alt={ item.backgroundAlt }
            key={ i }
            className={ `${i === index && 'active'}` }
          />
        ))}
      </Background>
      <GridContainer>
        <Wrapper>
          <Content>
            <Label>{label}</Label>
            <ListWrapper>
              <List ref={ listRef }>
                {isSingleItem
                  ? items.map((item, i) => (
                    <ItemLink to={ item.href } tabindex>
                      <Item key={ i } className='active'>
                        {item.name}
                      </Item>
                    </ItemLink>))

                  : items.map((item, i) => (
                    <Item
                      role='button'
                      tabindex={ 0 }
                      title={`Discover ${item.name} cellar door and book an experience`}
                      key={ i }
                      className={ `${i === index && 'active'}` }
                      onClick={ () => handleClick(i) }
                      onKeyDown={ () => handleKeyPress(i) }
                    >
                        {item.name}
                    </Item>
                  ))}
              </List>
              <AnimatedList path={ path }>
                {items.map((item, i) => (
                  <Item
                    onClick={ () => handleClick(i) }
                    key={ i }
                    className={ `${i === index && 'active'}` }
                  >
                    {item.name}
                  </Item>
                ))}
              </AnimatedList>
            </ListWrapper>
          </Content>
          <Images>
            <SwiperImageWrapper>
              <SwiperImage
                ref={ swiperRef }
                onSlideChange={ handleSwipe }
                slidesPerView={ 1 }
              >
                {items.map((item, i) => (
                  <SwiperSlide key={ i }>
                    <Image loading='lazy' src={ resolveAssetURL(item.image) } alt={ item.imageAlt } />
                  </SwiperSlide>
                ))}
              </SwiperImage>
            </SwiperImageWrapper>
            {items.map((item, i) => (
              <CircularLabelButton
                key={ i }
                id={id || "textcercle"}
                secondId={secondId || "textcercle2"}
                label={ item?.label ? item.label : item.name }
                to={ item.href }
                color='white'
                className={ `${i === index && 'active'}` }
                nbLabels={ isMobile ? 3 : 4 }
              />
            ))}
          </Images>
        </Wrapper>
      </GridContainer>
    </Container>
  )
}

WinerySlider.propTypes = {
  label: PropTypes.string,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      background: PropTypes.string,
      backgroundAlt: PropTypes.string,
      image: PropTypes.string,
      imageAlt: PropTypes.string,
      label: PropTypes.string,
      href: PropTypes.string
    })
  )
}

export default WinerySlider
