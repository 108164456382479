/**
 * Get Offset Position of an element relative to his parent
 *
 * @param child
 * @param parent
 *
 * @returns {{top: number, left: number, bottom: number, right: number}}
 */
export default function getRelativeOffset (child, parent) {
  const parentPos = parent.getBoundingClientRect()
  const childPos = child.getBoundingClientRect()

  return {
    top: childPos.top - parentPos.top,
    right: childPos.right - parentPos.right,
    bottom: childPos.bottom - parentPos.bottom,
    left: childPos.left - parentPos.left
  }
}
